<template>
  <div class="activityDialog">
    <el-dialog
      top="3%"
      width="50%"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <div class="tableBox">
        <el-table
          :data="tableData"
          :header-cell-style="{ textAlign: 'center' }"
          border
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            align="center"
            width="80"
            header-align="center"
          >
          </el-table-column>
          <el-table-column prop="createTime" width="180" label="报告时间" align="center">
            <template slot-scope="scope"
              >{{ scope.row.createTime | formatTime("YMDHMS") }}
            </template>
          </el-table-column>
          <el-table-column prop="height" label="身高" align="center">
            <template slot-scope="scope">{{ scope.row.height }}cm </template>
          </el-table-column>
          <el-table-column prop="height" align="center" label="身高评估">
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.heightStatus === '矮小'
                    ? 'c_red'
                    : scope.row.heightStatus === '偏矮'
                    ? 'c_brown'
                    : scope.row.heightStatus === '偏高'
                    ? 'c_brown'
                    : scope.row.heightStatus === '高'
                    ? 'c_yellow'
                    : 'c_green'
                "
                >{{ scope.row.heightStatus }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="weight" label="体重" align="center">
            <template slot-scope="scope">{{ scope.row.weight }}kg</template>
          </el-table-column>
          <el-table-column prop="weightStatus" align="center" label="体重评估">
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.weightStatus === '偏瘦'
                    ? 'c_brown'
                    : scope.row.weightStatus === '偏重'
                    ? 'c_yellow'
                    : scope.row.weightStatus === '肥胖'
                    ? 'c_red'
                    : 'c_green'
                "
                >{{ scope.row.weightStatus }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop label="操作" width="120" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="seeReport(scope.row)"
                >查看报告</el-button
              >
              <!-- <el-button @click="deleteData(scope.row)" type="text" size="small"
              >删除</el-button
            > -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="fenye">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.page"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="报告详情" :visible.sync="isShowReport" width="30%">
      <ol class="reportBox">
        <li v-for="item in reportTextList" :key="item">{{ item }}</li>
      </ol>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowReport = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      isShowReport: false,
      dialogTitle: "历史报告",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      page: {
        toPageNum: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
      reportTextList: [],
    };
  },
  components: {},
  methods: {
    seeReport(item) {
      //查看报告
      if (!item.reportText) {
        this.$message.error("暂无报告数据");
        return;
      }
      this.reportTextList = item.reportText.split("**");
      this.isShowReport = true;
    },
    indexMethod(index) {
      return index + 1 + (this.page.toPageNum - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.toPageNum = page;
      this.initPage();
    },
    closeEvent() {
      this.visible = false;
    },
    getInfo(item) {
      console.log(item);
      this.getAjax(
        "api/admin/faceUser/historyPage",
        { ...this.page, faceUserId: item.id },
        "post"
      ).then((res) => {
        this.tableData = res.data.content;
        this.total = res.data.totalElements;
        this.visible = true;
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.reportBox {
  padding-left: 30px;
  li {
    list-style: auto;
  }
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
