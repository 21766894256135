<template>
  <div class="userList">
    <searchBar
      ref="searchBar"
      :formItems="formItems"
      @search="search"
      @changeSelect="changeSelect"
    />
    <div style="margin-bottom: 10px">
      <el-upload
        class="upload-demo"
        ref="upload"
        :show-file-list="false"
        action="/api/admin/import"
        :on-success="handleSuccess"
      >
        <el-button slot="trigger" size="small" type="success">
          <i class="el-icon-upload"></i>
          导入人脸用户</el-button
        >
      </el-upload>
    </div>
    <div class="tableBox">
      <el-table
        :data="tableData"
        :header-cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="center"
          width="80"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          prop="nickName"
          width="150px"
          label="用户名"
          align="left"
        >
          <template slot-scope="scope">
            <div style="text-align: left">
              <span :class="scope.row.sex ? 'c_red' : 'c_blue'"
                >【{{ scope.row.sex | sex }}】</span
              >
              {{ scope.row.nickName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="height" label="头像" align="center">
          <template slot-scope="scope">
            <img
              :src="scope.row.capturePictureUrl"
              alt=""
              v-if="scope.row.capturePictureUrl"
              style="width: 50px"
            />
          </template>
        </el-table-column>

        <el-table-column
          prop="phone"
          label="手机号"
          align="center"
        ></el-table-column>
        <el-table-column prop="height" label="身高" align="center">
          <template slot-scope="scope">{{ scope.row.height }}cm </template>
        </el-table-column>
        <el-table-column prop="height" align="center" label="身高评估">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.heightStatus === '矮小'
                  ? 'c_red'
                  : scope.row.heightStatus === '偏矮'
                  ? 'c_brown'
                  : scope.row.heightStatus === '偏高'
                  ? 'c_brown'
                  : scope.row.heightStatus === '高'
                  ? 'c_yellow'
                  : 'c_green'
              "
              >{{ scope.row.heightStatus }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="weight" label="体重" align="center">
          <template slot-scope="scope">{{ scope.row.weight }}kg</template>
        </el-table-column>
        <el-table-column prop="weightStatus" align="center" label="体重评估">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.weightStatus === '偏瘦'
                  ? 'c_brown'
                  : scope.row.weightStatus === '偏重'
                  ? 'c_yellow'
                  : scope.row.weightStatus === '肥胖'
                  ? 'c_red'
                  : 'c_green'
              "
              >{{ scope.row.weightStatus }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="province" label="学校信息" width="300px">
          <template slot-scope="scope">
            <div class="line">
              <span>【地址】：</span>
              <em
                >{{ scope.row.province }} {{ scope.row.city }}
                {{ scope.row.area }}</em
              >
            </div>
            <div class="line">
              <span>【学校】：</span>
              <em>{{ scope.row.school }}</em>
            </div>
            <div class="line">
              <span>【年级】：</span>
              <em>{{ scope.row.classLevel }}</em>
            </div>
            <div class="line">
              <span>【班级】：</span>
              <em>{{ scope.row.classField }}</em>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="age"
          label="出生日期"
          align="center"
        ></el-table-column>
        <el-table-column prop label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="openHistoryReport(scope.row)"
              >历史报告</el-button
            >
            <!-- <el-button @click="deleteData(scope.row)" type="text" size="small"
              >删除用户</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <UserDialog ref="dialog" @search="initPage" />
    <el-dialog title="提示信息" :visible.sync="isShowDelete" width="30%">
      <div>确定删除该用户吗？</div>
      <div v-if="deleteTime">倒计时删除{{ deleteTime }}秒</div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="deleteTime" @click="cancelDeleteData()"
          >取消删除</el-button
        >
        <el-button v-else @click="deleteData()">确定</el-button>
        <el-button @click="isShowDelete = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
import UserDialog from "./components/dialog.vue";
export default {
  components: { searchBar, UserDialog },
  data() {
    return {
      deleteTime: "",
      timer: null,
      isShowDelete: false,
      page: {
        toPageNum: 1,
        pageSize: 10,
      },
      params: {},
      total: 0,
      tableData: [],
      adInfo: {
        province: "",
        city: "",
        area: "",
        school: "",
        classLevel: "",
        classField: "",
      },
      formItems: [
        {
          label: "",
          type: "el-input",
          fieldName: "username",
          attrs: {
            placeholder: "用户名",
          },
        },
        {
          label: "",
          type: "el-input",
          fieldName: "phone",
          attrs: {
            placeholder: "手机号码",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "province",
          data: [],
          attrs: {
            placeholder: "省",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "city",
          data: [],
          attrs: {
            placeholder: "市",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "area",
          data: [],
          attrs: {
            placeholder: "区",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "school",
          data: [],
          attrs: {
            placeholder: "学校",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "classLevel",
          data: [],
          attrs: {
            placeholder: "年级",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "classField",
          data: [],
          attrs: {
            placeholder: "班级",
          },
        },
      ],
    };
  },
  methods: {
    changeSelect(data) {
      if (data.item.fieldName === "province") {
        this.adInfo.province = data.opt;
        this.$refs.searchBar.formData.city = "";
        this.$refs.searchBar.formData.area = "";
        this.$refs.searchBar.formData.school = "";
        this.$refs.searchBar.formData.classLevel = "";
        this.$refs.searchBar.formData.classField = "";
        this.getAdInfo(3);
      } else if (data.item.fieldName === "city") {
        this.adInfo.city = data.opt;
        this.$refs.searchBar.formData.area = "";
        this.$refs.searchBar.formData.school = "";
        this.$refs.searchBar.formData.classLevel = "";
        this.$refs.searchBar.formData.classField = "";
        this.getAdInfo(4);
      } else if (data.item.fieldName === "area") {
        this.adInfo.area = data.opt;
        this.$refs.searchBar.formData.school = "";
        this.$refs.searchBar.formData.classLevel = "";
        this.$refs.searchBar.formData.classField = "";
        this.getAdInfo(5);
      } else if (data.item.fieldName === "school") {
        this.adInfo.school = data.opt;
        this.$refs.searchBar.formData.classLevel = "";
        this.$refs.searchBar.formData.classField = "";
        this.getAdInfo(6);
      } else if (data.item.fieldName === "classLevel") {
        this.adInfo.classLevel = data.opt;
        this.$refs.searchBar.formData.classField = "";
        this.getAdInfo(7);
      } else if (data.item.fieldName === "classField") {
        this.adInfo.classLevel = data.opt;
      }
    },
    handleSuccess(res) {
      //导入用户
      if (res.success) {
        this.$message.success("导入成功");
        this.initPage();
      } else {
        this.$message.error(res.errorMessage);
      }
    },
    indexMethod(index) {
      return index + 1 + (this.page.toPageNum - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.toPageNum = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.page.toPageNum = 1;
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/admin/faceUser/page",
        { ...this.params, ...this.page },
        "post"
      ).then((res) => {
        this.tableData = res.data.content;
        this.total = res.data.totalElements;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
    },
    openHistoryReport(item) {
      this.$refs.dialog.getInfo(item);
    },
    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax(
          "api/wx/user/base/delDefaultUser",
          { defaultFaceUser: item.id },
          "post"
        ).then((res) => {
          this.$message.success("删除成功");
          this.initPage();
        });
      });
    },
    cancelDeleteData() {
      //取消删除
    },
    getAdInfo(num) {
      this.getAjax(
        "api/admin/getCity",
        {
          ...this.adInfo,
        },
        "post"
      ).then((res) => {
        this.formItems[num].data = res.data.map((e) => {
          return {
            label: e,
            value: e,
          };
        });
        for (let i = num + 1; i < 8; i++) {
          this.formItems[i].data = [];
        }
      });
    },
  },
  mounted() {
    this.initPage();
    this.getAdInfo(2);
  },
};
</script>

<style lang="less" scoped>
.line {
  display: flex;
  align-items: center;
  min-width: 300px;
  span {
    font-weight: bold;
    margin-right: 5px;
  }
}
</style>
