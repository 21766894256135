var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activityDialog"},[_c('el-dialog',{attrs:{"top":"3%","width":"50%","title":_vm.dialogTitle,"visible":_vm.visible,"close-on-click-modal":false,"before-close":_vm.closeEvent},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"tableBox"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"header-cell-style":{ textAlign: 'center' },"border":""}},[_c('el-table-column',{attrs:{"type":"index","index":_vm.indexMethod,"label":"序号","align":"center","width":"80","header-align":"center"}}),_c('el-table-column',{attrs:{"prop":"createTime","width":"180","label":"报告时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(_vm._f("formatTime")(scope.row.createTime,"YMDHMS"))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"height","label":"身高","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.height)+"cm ")]}}])}),_c('el-table-column',{attrs:{"prop":"height","align":"center","label":"身高评估"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:scope.row.heightStatus === '矮小'
                  ? 'c_red'
                  : scope.row.heightStatus === '偏矮'
                  ? 'c_brown'
                  : scope.row.heightStatus === '偏高'
                  ? 'c_brown'
                  : scope.row.heightStatus === '高'
                  ? 'c_yellow'
                  : 'c_green'},[_vm._v(_vm._s(scope.row.heightStatus))])]}}])}),_c('el-table-column',{attrs:{"prop":"weight","label":"体重","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.weight)+"kg")]}}])}),_c('el-table-column',{attrs:{"prop":"weightStatus","align":"center","label":"体重评估"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:scope.row.weightStatus === '偏瘦'
                  ? 'c_brown'
                  : scope.row.weightStatus === '偏重'
                  ? 'c_yellow'
                  : scope.row.weightStatus === '肥胖'
                  ? 'c_red'
                  : 'c_green'},[_vm._v(_vm._s(scope.row.weightStatus))])]}}])}),_c('el-table-column',{attrs:{"prop":"","label":"操作","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.seeReport(scope.row)}}},[_vm._v("查看报告")])]}}])})],1)],1),_c('div',{staticClass:"fenye"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.page.page,"page-sizes":[5, 10, 20, 30, 50],"page-size":_vm.page.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.closeEvent()}}},[_vm._v("关 闭")])],1)]),_c('el-dialog',{attrs:{"title":"报告详情","visible":_vm.isShowReport,"width":"30%"},on:{"update:visible":function($event){_vm.isShowReport=$event}}},[_c('ol',{staticClass:"reportBox"},_vm._l((_vm.reportTextList),function(item){return _c('li',{key:item},[_vm._v(_vm._s(item))])}),0),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.isShowReport = false}}},[_vm._v("关 闭")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }